<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="resetForm" #default="{ invalid }">
      <b-form class="form-reset" ref="reset" @submit.prevent="reset">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group :label="$t('message.Old_Password')" label-for="account-old-password">
              <validation-provider
                #default="{ errors }"
                :name="$t('message.Old_Password')"
                vid="passwordValueOld"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    :placeholder="$t('message.Old_Password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group label-for="account-new-password" :label="$t('message.New_Password')">
              <validation-provider
                #default="{ errors }"
                :name="$t('message.New_Password')"
                vid="newPasswordValue"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="newPasswordValue"
                    :placeholder="$t('message.New_Password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              :label="$t('message.Retype_New_Password')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('message.Retype_New_Password')"
                vid="RetypePassword"
                rules="required|confirmed:newPasswordValue"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="RetypePassword"
                    :placeholder="$t('message.Retype_New_Password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              type="submit"
              :disabled="invalid"
            >
              {{ $t('message.save_changes') }}
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              {{ $t('message.reset') }}
            </b-button> -->
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import useJwt from "@/auth/jwt/useJwt";
import { required } from "@validations";
import store from '@/store';
import i18n from '@/libs/i18n';

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",

      required,
      confirm,
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
    async reset() {
      const { email, id } = store.state?.userStore?.userData;
      if (this.passwordValueOld != "") {
        try {
          const logUser = await useJwt.login({
            email: email,
            password: this.passwordValueOld,
          });
          if (logUser) {
            const { id, accessToken, refreshToken } = useJwt.realm.currentUser;
            useJwt.realm.emailPasswordAuth
              .callResetPasswordFunction({
                email,
                password: this.newPasswordValue,
              })
              .then((response) => {
                this.$refs.reset.reset();
                this.showSuccessMessage(i18n.t('message.password_updated'));
              })
              .catch((error) => {
                this.$refs.reset.reset();
                this.showErrorMessage(i18n.t('message.something_went_wrong'));
              });
          }
        } catch (e) {
          this.$refs.reset.reset();
          this.showErrorMessage(i18n.t('message.old_password_invalid'));
        }
      }
    },
  },
};
</script>
